import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { media, styled } from "@styles";
var padding = {
  md: "0.65rem",
  sm: "0.2rem"
};
export var Primary = styled.button(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: ", ";\n  padding: 0.65rem 30px;\n  transition: 0.0s;\n  outline: none;\n  cursor: pointer;\n  color: ", ";\n  min-width: ", ";\n  width: fit-content;\n  border-radius: 9px;\n\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  &:disabled {\n    background-color: ", ";\n\n    &,\n    &:hover {\n      cursor: default;\n    }\n  }\n\n  ", "\n"])), function (props) {
  return props.theme.button.colors[props.color].background;
}, function (props) {
  return padding[props.size];
}, function (props) {
  return props.theme.button.colors[props.color].color;
}, function (props) {
  return props.fullWidth ? "100%" : "150px";
}, function (props) {
  return props.theme.button.colors[props.color].hoverBackground;
}, function (props) {
  return props.theme.button.colors[props.color].hoverColor;
}, function (props) {
  return props.theme.colors.disabled;
}, media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding:  0.65rem;\n    width: ", ";\n  "])), function (props) {
  return props.fullWidth ? "100%" : "100%";
}));
export var Secondary = styled(Primary)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: black;\n  &:hover {\n    background-color: #F4F4F4;\n    color: black;\n  }\n  border: 1px solid black;\n"])));
export var Text = styled.span(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: inline-block;\n  font-size: 14px;\n  font-weight: ", ";\n  line-height: ", ";\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.baseLineHeight;
});