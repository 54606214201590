import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import { styled } from "@styles";
export var ColumnWrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n"])));
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  @media(max-width: 540px) {\n    height: 200px;\n  }\n"])));
export var AddressWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 350px;\n  height: 350px;\n  padding: 20px;\n"])));
export var AddressTitle = styled.h1(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: #7d7d7d;\n  font-size: 1rem;\n"])));
export var AddressField = styled.h2(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  color: black;\n  font-size: 0.875rem;\n"])));
export var TitleWrapper = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  margin-top: 45%;\n"])));
export var FieldWrapper = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-top: 5%;\n"])));
export var ButtonWrapper = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n\n"])));
export var ButtonContainer = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin-top: 25px;\n"])));