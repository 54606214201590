import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  font-size: ", ";\n  grid-template-areas:\n    \". subtotalText subtotalPrice .\"\n    ", "\n    ", "\n    \". totalText totalPrice .\";\n  grid-template-columns: 4fr 1.1fr 0.9fr 0.5fr;\n  grid-gap: 2rem;\n  padding: 2rem 0;\n  ", "\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.showShipping && "\". shippingText shippingPrice .\"";
}, function (props) {
  return props.showDiscount && "\". discountText discountPrice .\"";
}, function (props) {
  return media.mediumScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    grid-template-areas:\n      \". subtotalText subtotalPrice\"\n      ", "\n      ", "\n      \". totalText totalPrice\";\n    grid-template-columns: 0.5fr 3.5fr 2fr;\n  "])), props.showShipping && "\". shippingText shippingPrice\"", props.showDiscount && "\". discountText discountPrice\"");
});
export var SubtotalText = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-area: subtotalText;\n"])));
export var SubtotalPrice = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: subtotalPrice;\n  ", "\n"])), media.mediumScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var ShippingText = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: shippingText;\n"])));
export var ShippingPrice = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  grid-area: shippingPrice;\n  ", "\n"])), media.mediumScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var DiscountText = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  grid-area: discountText;\n"])));
export var DiscountPrice = styled.div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  grid-area: discountPrice;\n  ", "\n"])), media.mediumScreen(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));
export var TotalText = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  grid-area: totalText;\n  font-weight: bold;\n"])));
export var TotalPrice = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  font-weight: bold;\n  ", "\n"])), media.mediumScreen(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    text-align: right;\n  "]))));