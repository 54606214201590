import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var HeaderContent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  align-items: center;\n"])), function (props) {
  return props.theme.colors.dark;
});
export var FooterContent = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  > div {\n    display: inline-block;\n    padding: 0;\n    margin: 0;\n    margin-right: 0.6rem;\n  }\n"])));
export var MenuItem = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  border-radius: 6px;\n  padding-left: .75rem;\n  padding-right: .75rem;\n  padding-top: .21rem;\n  padding-bottom: .21rem;\n  :hover {\n    background-color: ", ";\n    color: ", ";\n  }\n"])), function (props) {
  return props.theme.colors.primaryLight;
}, function (props) {
  return props.theme.colors.lightFont;
});