import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 80px 0;\n\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin: 40px 0;\n  "]))));
export var ThankYouHeader = styled.p(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 110%;\n  span {\n    font-weight: ", ";\n  }\n  padding-bottom: 40px;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 40px;\n\n  ", "\n"])), function (props) {
  return props.theme.typography.ultraBigFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "])), function (props) {
  return props.theme.typography.h1FontSize;
}));
export var Paragraph = styled.p(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 170%;\n\n  span {\n    font-weight: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Buttons = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 50%;\n  margin-top: 40px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 30px;\n  button {\n    padding-left: 0;\n    padding-right: 0;\n  }\n\n  ", "\n"])), media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 20px;\n    width: 100%;\n    margin-top: 20px;\n  "]))));