import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  min-height: 140px;\n  max-height: min-content;\n  width: 100%;\n  grid-template-areas: \"photo description unitPrice quantity totalPrice trash\";\n  grid-template-columns: 0.5fr 2fr 1fr 1fr 1fr 0.5fr;\n  align-items: center;\n  border-bottom: 1px solid rgba(50, 50, 50, 0.1);\n  padding: 0.8rem 0.5rem;\n  ", ";\n"])), media.mediumScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr 2fr 2fr;\n    grid-row-gap: 15px;\n    grid-column-gap: 20px;\n    grid-template-areas: \"photo description description\"\n    \"trash description description\"\n    \"trash unitPrice quantity\"\n    \". . totalPrice\";\n    padding: 1rem 0rem;\n  "]))));
export var QuantityButtons = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  padding: 0;\n  margin: 0 15px 0 0;\n  width: 50px;\n\n  > div {\n    display: flex;\n  }\n\n  svg {\n    cursor: pointer;\n    justify-self: center;\n  }\n"])));
export var Photo = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: photo;\n  display: flex;\n  align-items: flex-start;\n  align-self: top;\n  width: 70px;\n  height: 90px;\n\n  background-color: #f1f5f5;\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: contain;\n  }\n"])));
export var Description = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-area: description;\n  height: 100%;\n  margin-top: 20px;\n  margin-left: 20px;\n  ", "\n"])), media.mediumScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    margin-left: 0px;\n  "]))));
export var Sku = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin: 6px 0;\n  text-align: left;\n  margin-bottom: 10px;\n"])));
export var Attributes = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-auto-columns: max-content;\n  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));\n  margin-left: -15px;\n  ", ";\n"])), media.mediumScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    flex-flow: column;\n  "]))));
export var SingleAttribute = styled.p(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  margin: 0;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  white-space: nowrap;\n  background-color: white;\n  padding: 0px 15px;\n"])));
export var Name = styled.p(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  font-size: ", ";\n  text-align: left;\n  text-transform: none;\n  margin-bottom: 6px;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h4FontSize;
});
export var LightFont = styled.span(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: rgba(125, 125, 125, 0.6);\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.div(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  justify-content: center;\n  font-weight: bold;\n  ", "\n\n  p {\n    margin: 0;\n  }\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, media.mediumScreen(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n    font-weight: normal;\n    flex-direction: column;\n  "]))));
export var PriceLabel = styled.p(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  display: none;\n  ", "\n"])), media.mediumScreen(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n    display: block;\n  "]))));
export var TotalPrice = styled(Price)(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  grid-area: totalPrice;\n  ", "\n"])), media.mediumScreen(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n    p {\n      text-align: right;\n    }\n  "]))));
export var Trash = styled.div(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  grid-area: trash;\n"])));
export var UnitPrice = styled(Price)(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  grid-area: unitPrice;\n"])));
export var Quantity = styled.div(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  min-width: 120px;\n  margin: 0 15px;\n"])));