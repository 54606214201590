import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { media, styled } from "@styles"; // background: ${props => props.theme.colors.light};
// :hover {
//  background-color: ${props => props.theme.colors.hoverLightBackground};
// }

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  \n  text-align: center;\n  max-height: 30rem;\n  transition: 0.3s;\n\n  ", "\n"])), media.largeScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 10px;\n  "]))));
export var Content = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding: 5px 5px;\n"])));