import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 30px 0 100px 0;\n"])));
export var Breadcrumbs = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral([""])));
export var Title = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  margin-top: 80px;\n  margin-bottom: 60px;\n"])));
export var CartHeader = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", ";\n"])), media.mediumScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    display: none;\n  "]))));
export var CartFooter = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral([""])));
export var Cart = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  border-top: 1px solid rgba(50, 50, 50, 0.1);\n"])));
export var ProceedButton = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  text-align: right;\n  margin-top: 40px;\n  ", ";\n"])), media.smallScreen(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    text-align: center;\n  "]))));