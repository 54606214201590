import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 300px;\n    justify-content: space-evenly;\n    @media(max-width: 540px) {\n        height: 80vh;\n    }\n"])));
export var Title = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    font-size: 1.75rem;\n    text-align: center;\n    color: #431933;\n"])));
export var Header = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    font-size: 1.75rem;\n    line-height: 3rem;\n    text-align: center;\n    color: #431933;\n    width: 80%;\n    margin: 0 auto;\n    @media(max-width: 540px) {\n        width: 95%;\n    }\n"])));
export var Body = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: 1.15rem;\n    text-align: center;\n    @media(max-width: 540px) {\n        line-height: 2rem;\n    }\n"])));
export var SuccessMessage = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    font-size: 1rem;\n    text-align: center;\n    color: green;\n    height: 10px;\n    margin-top: -40px;\n"])));
export var Footer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    font-size: 0.75rem;\n    text-align: center;\n"])));
export var TextField = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n\n"])));
export var SubmitButton = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    display: flex;\n    justify-content: center;\n    margin-top: 15px;\n"])));