import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { media, styled } from "@styles";
export var PaymentForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
export var PaymentInput = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));
PaymentInput.displayName = "S.PaymentInput";
export var Grid = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  ", "\n  & > div {\n    padding-right: ", ";\n    &:last-child {\n      padding-right: 0;\n    }\n    ", "\n  }\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n     flex-direction: column;\n  "]))), function (props) {
  return props.theme.spacing.spacer;
}, media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      padding-right:  0;\n      \n    "]))));