import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"])));
export var Tile = styled.label(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: block;\n  background-color: transparent;\n  cursor: pointer;\n  margin-bottom: 4px;\n"])));
export var CreditForm = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  width: 76%;\n  ", "\n  border: 1px solid black;\n  padding: 20px;\n  border-radius: 4px;\n"])), media.smallScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      width: 100%;\n    "]))));
export var CardLogoContainer = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: left;\n  margin-left: -20px;\n  background: transparent;\n  width: 60%;\n  margin-left: 30px;\n  margin-top: -16px;\n  ", "\n"])), media.smallScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      width: 98%;\n      justify-content: center;\n      margin-left: 0;\n  "]))));
export var CardContainer = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  height: auto;\n  width: 70%;\n  margin-top: auto;\n  margin-bottom: auto;\n  ", "\n"])), media.smallScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      width: 98%;\n  "]))));
export var CreditFormTop = styled.div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  justfiy-contect: space-between;\n"])));