import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  width: 100%;\n\n  h3 {\n    font-weight: ", ";\n    text-transform: none;\n  }\n\n  p {\n    color: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var GeneralError = styled.p(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", " !important;\n"])), function (props) {
  return props.theme.colors.error;
});
export var InputFields = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 10.5rem;\n  margin: 1rem auto;\n"])));