import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin: 100px 0 100px 0;\n\n  ", "\n"])), media.mediumScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin: 80px 0 80px 0;\n  "]))));
export var TitleFirstLine = styled.h1(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: ", ";\n\n  ", "\n"])), function (props) {
  return props.theme.typography.h1FontSize;
}, function (props) {
  return media.mediumScreen(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "])), props.theme.typography.h2FontSize);
});
export var TitleSecondLine = styled.h1(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: bold;\n\n  ", "\n"])), function (props) {
  return props.theme.typography.h1FontSize;
}, function (props) {
  return media.mediumScreen(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "])), props.theme.typography.h2FontSize);
});
export var HR = styled.hr(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 40px 0;\n  padding: 0;\n\n  ", "\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.mediumScreen(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    margin: 30px 0;\n  "]))));
export var Subtitle = styled.p(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  margin: 40px 0;\n\n  ", "\n"])), media.mediumScreen(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    margin: 30px 0;\n  "]))));
export var ContinueButton = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral([""])));