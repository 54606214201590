import * as acceptwrapper from "./acceptwrapper";
export var authorizeNetPayment = function authorizeNetPayment(creditCard) {
  return new Promise(function (resolve, reject) {
    acceptwrapper.createPayment(creditCard).then(function (response) {
      if (response.error) {
        reject(response.error._error);
      } else {
        var lastDigits = creditCard.number.slice(creditCard.number.length - 4);
        var token = response.opaqueData.dataValue;
        resolve({
          lastDigits: lastDigits,
          ccType: 'ANY',
          token: token
        });
      }
    });
  });
};