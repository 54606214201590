import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 1.4rem;\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    margin-top: 27.5rem;\n    "]))));
export var Bar = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: 3.3rem;\n  background-color: #431933;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 2rem;\n  font-size: ", ";\n  margin-top: 2rem;\n  margin-bottom: 1.4rem;\n  border-radius: 6px;\n  color: white;\n  @media(max-width: 540px) {\n    height: 3.3rem;\n    background-color: #431933;\n    padding: 0 1rem;\n    font-size: ", ";\n    margin-top: 2rem;\n    margin-bottom: 1.4rem;\n    border-radius: 6px;\n    color: white;\n  }\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.typography.baseFontSize;
});
export var LeftSide = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var RightSide = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  height: 1.2rem;\n  @media(max-width: 540px) {\n    height: 2.4rem;\n  }\n"])));
export var FiltersButton = styled.button(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  color: white;\n  @media(max-width: 540px) {\n    font-size: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.typography.baseFontSize;
});
export var Clear = styled.button(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n  cursor: pointer;\n  font-size: ", ";\n  color: ", "; \n  @media(max-width: 540px) {\n    font-size: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.typography.baseFontSize;
});
export var Element = styled.span(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  padding-left: 2rem;\n  @media(max-width: 540px) {\n    padding-left: 1rem;\n  }\n"])));
export var Filters = styled.span(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0.6rem;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Label = styled.span(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  color: white;\n"])));
export var Sort = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  width: 12rem;\n  display: inline-block;\n"])));
export var FiltersChipsWrapper = styled.div(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  > div {\n    margin: 0.4rem;\n    color: ", "; \n  }\n"])), function (props) {
  return props.theme.colors.lightFont;
});