import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Divider = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1rem 0;\n  font-size: 16.5px;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var DiscountField = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  padding: 30px;\n\n  ", "\n"])), function (props) {
  return props.theme.colors.light;
}, media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]))));
export var Tile = styled.label(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  display: block;\n  background-color: ", ";\n  padding: 20px;\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
});
export var CheckBoxText = styled.span(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  font-size: 13px;\n"])));