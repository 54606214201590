import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import { styled } from "@styles";
export var Content = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  text-transform: none;\n  font-size: ", ";\n  text-align: center;\n  vertical-align: center;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n\n  p {\n    margin: 0;\n    margin-bottom: calc(", " / 3);\n    font-weight: ", ";\n  }\n"])), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.spacing.spacer;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});