import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles";
import { activeLabelStyles } from "../InputLabel";

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      error = _ref.error,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return theme.colors.disabled;
  }

  if (error) {
    return theme.colors.error;
  }

  if (hovered) {
    return theme.colors.secondary;
  }

  return active ? theme.colors.secondary : theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  border: 1px solid ", ";\n  color: ", ";\n  outline: ", ";\n  transition: all 0.3s ease;\n  &:hover {\n    color: ", ";\n    outline-width: ", "px;\n    outline-style: solid;\n    border-color: ", ";\n    outline-color: ", ";\n  }\n  width: 27%;\n  height: 38px;\n  overflow: hidden;\n  border-radius: 6px;\n"])), function (props) {
  return getEdgeColor(props);
}, function (props) {
  return getEdgeColor(props);
}, function (props) {
  return props.active ? "1px solid ".concat(getEdgeColor(props), ";") : "none";
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return props.disabled ? 0 : 1;
}, function (props) {
  return getEdgeColor(props, true);
}, function (props) {
  return getEdgeColor(props, true);
});
export var Content = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n"])));
export var InputWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: relative;\n  width: 100%;\n"])));
export var Input = styled.input(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  padding: 0.5rem 1rem;\n  margin: 0;\n  border: none;\n  width: 100%;\n  height: 38px;\n  font-size: ", ";\n  outline: none;\n  background-color: white;\n  &:-webkit-autofill {\n    & + label {\n      ", ";\n    }\n  }\n"])), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return activeLabelStyles(props.theme, props.labelBackground);
});