import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13;

import { styled } from "@styles";
import { Link as _Link } from "react-router-dom";
import ReactSVG from "react-svg";
import { css } from "styled-components";
import { NavLink as _NavLink } from "@components/atoms";
import { NAVBAR_HEIGHT } from "../TopNavbar/styles";
var MenuItemStyles = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  outline: none;\n  padding: 0 25px 0px 15px;\n  text-transform: none;\n  transition: 100ms;\n  height: ", ";\n  width: 100%;\n  ", "\n\n  path {\n    transition: 300ms;\n  }\n\n  &:hover,\n  &:focus {\n    ", "\n\n    path {\n      fill: ", ";\n    }\n  }\n"])), NAVBAR_HEIGHT, function (_ref) {
  var theme = _ref.theme;
  return "\n    border-bottom: 1px solid ".concat(theme.colors.divider, ";\n    font-weight: ").concat(theme.typography.boldFontWeight, ";\n    font-size: ").concat(theme.typography.baseFontSize, ";\n  ");
}, function (_ref2) {
  var theme = _ref2.theme;
  return "\n      color: ".concat(theme.colors.primary, ";\n      background-color: ").concat(theme.colors.hoverLightBackground, ";\n    ");
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.primary;
});
export var Wrapper = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  max-width: calc(100vw - 5rem);\n  width: 30rem;\n  overflow: hidden;\n"])));
export var Bar = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  height: ", ";\n  padding: 0 15px;\n  border-bottom: 1px solid ", ";\n"])), NAVBAR_HEIGHT, function (props) {
  return props.theme.colors.divider;
});
export var Menu = styled.ul(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  background-color: ", ";\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  margin: 0;\n  padding: 0;\n"])), function (props) {
  return props.theme.colors.white;
});
export var Item = styled.li(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral([""])));
export var NavButton = styled.button(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", ";\n"])), MenuItemStyles);
export var NavLink = styled(_NavLink).attrs({
  fullWidth: true
})(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", ";\n"])), MenuItemStyles);
export var Link = styled(_Link)(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  ", ";\n"])), MenuItemStyles);
export var LogoWrapper = styled(ReactSVG)(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  line-height: 0;\n\n  svg {\n    width: 30px;\n    height: 30px;\n  }\n"])));
export var IconWrapper = styled.span(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  line-height: 1;\n  margin-right: ", ";\n"])), function (_ref4) {
  var theme = _ref4.theme;
  return theme.spacing.spacer;
});
export var SubcategoryIcon = styled.div(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  margin-left: auto;\n"])));
export var BackButton = styled(NavButton)(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  color: #7d7d7d;\n  padding: 0;\n\n  &:hover {\n    background-color: transparent;\n  }\n"])));
export var CloseIconWrapper = styled.button(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  padding: 5px;\n\n  path {\n    transition: 300ms;\n  }\n\n  &:hover,\n  &:focus {\n    path {\n      fill: ", ";\n    }\n  }\n"])), function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.primary;
});