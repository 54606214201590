import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background-color: ", ";\n  height: 100%;\n  /* height: auto; */\n  padding-left: 3rem;\n  padding-top: 2.5rem;\n"])), function (props) {
  return props.theme.colors.light;
});
export var MenuHeader = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  font-weight: ", ";\n  text-transform: \"uppercase\";\n  padding-bottom: 2rem;\n"])), function (props) {
  return props.theme.typography.h3FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var MenuItem = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  cursor: pointer;\n  padding-bottom: 1.5rem;\n  color: ", ";\n"])), function (props) {
  return props.active ? props.theme.colors.activeMenuOption : "";
});