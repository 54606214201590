import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { styled } from "@styles";
export var TextFieldStandard = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: ", ";\n  position: relative;\n  margin-top: -10px;\n"])), function (props) {
  return props.theme.spacing.fieldSpacer;
});
TextFieldStandard.displayName = "S.TextField";
export var HelpText = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.input.labelColor;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 100%;\n"])));