import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { media, styled } from "@styles";
import { css, keyframes } from "styled-components";

var getTranslate = function getTranslate(side) {
  return side === "left" ? "-100%" : "100%";
};

var slideAnimation = function slideAnimation(open, side) {
  var initialValue = open ? getTranslate(side) : 0;
  var endValue = open ? 0 : getTranslate(side);
  return keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    from {\n      transform: translateX(", ");\n    }\n    to {\n      transform: translateX(", ");\n    }"])), initialValue, endValue);
};

var opacity = {
  entered: 1,
  entering: 0,
  exited: 0,
  exiting: 0,
  unmounted: 0
};
var justify = {
  center: "center",
  left: "flex-start",
  right: "flex-end"
};
var lightboxWidth = {
  center: "auto",
  left: "100%",
  right: "100%"
};

var lightboxHeight = function lightboxHeight(width) {
  return {
    center: "".concat(width, "px"),
    left: "auto",
    right: "auto"
  };
};

export var Lightbox = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  position: relative;\n  width: ", ";\n  min-height: ", "px;\n  border: 1px solid $white;\n  border-radius: 15px;\n  height: ", ";\n  ", "\n  background-color: ", ";\n  ", "\n"])), function (_ref) {
  var position = _ref.position,
      modal = _ref.theme.modal;
  return lightboxHeight(modal.modalWidth)[position];
}, function (props) {
  return props.theme.modal.modalMinHeight;
}, function (_ref2) {
  var position = _ref2.position;
  return lightboxWidth[position];
}, media.smallScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    height: 100vh;\n    width: 100vw;\n    border-radius: 0px;\n  "]))), function (props) {
  return props.theme.colors.white;
}, function (_ref3) {
  var open = _ref3.open,
      position = _ref3.position;

  if (position === "left" || position === "right") {
    return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n        ", ": 0;\n        transform: translateX(", ");\n        animation: ", " 0.1s both;\n        \n      "])), position, getTranslate(position), slideAnimation(open, position));
  }
});
Lightbox.displayName = "S.Lightbox";
export var Overlay = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  display: flex;\n  position: fixed;\n  overflow-y: auto;\n  width: 100%;\n  height: 100%;\n  min-height: 100vh;\n  top: 0;\n  z-index: 2;\n  background-color: rgba(67, 25, 51, 0.5);\n  align-items: center;\n  justify-content: ", ";\n  opacity: ", ";\n"])), function (_ref4) {
  var position = _ref4.position;
  return justify[position];
}, function (_ref5) {
  var state = _ref5.state;
  return opacity[state];
});
Overlay.displayName = "S.Overlay";