import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import { styled } from "@styles";
export var TextFieldCheckout = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  margin-bottom: 29px;\n  position: relative;\n"])));
TextFieldCheckout.displayName = "S.TextField";
export var HelpText = styled.span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.input.labelColor;
}, function (props) {
  return props.theme.input.labelFontSize;
});
export var ErrorMessages = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 100%;\n"])));
export var Suggestions = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  position: absolute;\n  top: 100%;\n  z-index: 1000;\n  width: 100%;\n  margin-top: 0;\n  max-height: 200px;\n  overflow-y: scroll;\n  border-left: 1px solid #999999;\n  border-right: 1px solid #999999;\n  border-bottom: 1px solid ", ";\n"])), function (props) {
  return props.show ? "#999999" : "none";
});
export var Suggestion = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding-top: 14px;\n  padding-bottom: 14px;\n  padding-left: 20px;\n  background-color: #FFFFFF;\n  width: 100%;\n  font-size: 12px;\n  color: black;\n  &:hover {\n    background-color: #F4F5F6;\n    color: black;\n    cursor: pointer;\n  }\n  display: flex;\n  justify-content: space-between;\n"])));
export var SuggText = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  \n  \n"])));
export var ChevronRight = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  margin-right: 14px;\n  padding-top: 2.8px;\n"])));