import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  background: white;\n  border: 1px solid #d3d3d3;\n  border-radius: 5px;\n  padding: 10px;\n  display: grid;\n  position: relative;\n  grid-row-gap: 6px;\n  grid-column-gap: 20px;\n  grid-template-columns: 60px auto auto;\n  grid-template-areas:\n    \"photo name name\"\n    \"photo sku .\"\n    \"photo . .\"\n    \"photo quantity price\";\n"])));
export var Photo = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  grid-area: photo;\n  width: 50px;\n  position: absolute;\n  top: 55%;\n  transform: translate(0, -50%);\n  img {\n    width: 100%;\n  }\n"])));
export var Sku = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  grid-area: sku;\n  font-size: 12px;\n  color: #666666;\n  width: 125px;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  display: block;\n  overflow: hidden;\n"])));
export var Name = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  grid-area: name;\n  font-size: 12px;\n  color: black;\n"])));
export var Price = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  grid-area: price;\n  text-align: right;\n  font-size: 12px;\n  color: black;\n  font-weight: bold;\n"])));
export var Quantity = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  grid-area: quantity;\n  font-size: 12px;\n  color: #666666;\n"])));