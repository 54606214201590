import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: grid;\n  grid-template-areas: \"sidebar preview\";\n  height: 540px;\n  max-height: 540px;\n  grid-template-columns: 76px 1fr;\n  grid-column-gap: 20px;\n  position: relative;\n"])));
export var Thumbnail = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: 88.83px;\n  height: 110px;\n  display: flex;\n  border-width: 2px;\n  border-style: solid;\n  border-color: ", ";\n  opacity: ", ";\n  justify-content: center;\n  height: 100px;\n  overflow: hidden;\n  margin-bottom: 10px;\n  border-radius: 7px;\n"])), function (props) {
  return props.activeThumbnail === true ? "#D3D3D3" : "#FFFFFF";
}, function (props) {
  return props.activeThumbnail === true ? "1" : "0.75";
});
export var Button = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: 50px;\n  width: 89px;\n  position: absolute;\n  z-index: 1;\n  background-color: rgba(50, 50, 50, 0.3);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n"])));
export var TopButton = styled(Button)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  top: 0%;\n  transform: rotate(180deg);\n"])));
export var BottomButton = styled(Button)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  bottom: 0%;\n"])));
export var ThumbnailsContainer = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  position: relative;\n"])));
export var ThumbnailList = styled.div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  position: relative;\n  height: 100%;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  scrollbar-width: none;\n  ::-webkit-scrollbar {\n    width: 0px;\n  }\n\n  ul {\n    position: absolute;\n    display: block;\n    padding: 0;\n    margin: 0;\n  }\n  width: 96px;\n"])));
export var Preview = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  grid-area: preview;\n  overflow: hidden;\n  width: 385px;\n  height: 540px;\n  position: absolute;\n  left: 50%;\n  transform: translate(-50%, 0);\n  img {\n    width: 100%;\n    object-fit: contain;\n    border-radius: 12px;\n    border: 1px solid #D3D3D3;\n  }\n"])));