import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4;

import { styled } from "@styles"; // todo move colors into the theme

var getEdgeColor = function getEdgeColor(_ref) {
  var active = _ref.active,
      disabled = _ref.disabled,
      theme = _ref.theme;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (disabled) {
    return theme.colors.disabled;
  }

  return active ? theme.colors.secondary : theme.colors.dark;
};

export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  color: ", ";\n  width: 100%;\n  max-width: 500px;\n  -webkit-flex-wrap: wrap;\n  flex-wrap: wrap;\n  margin-top: 10px;\n  @media(max-width: 540px){margin-left: -11px;}\n"])), function (props) {
  return getEdgeColor(props);
});

var getBgColor = function getBgColor(_ref2) {
  var selected = _ref2.selected;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (selected) {
    return '#666666';
  }

  return '#E9E7E8';
};

var getFontColor = function getFontColor(_ref3) {
  var selected = _ref3.selected;
  var hovered = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (selected) {
    return 'white';
  }

  return 'black';
};

var getOpacity = function getOpacity(_ref4) {
  var disabled = _ref4.disabled;

  if (disabled) {
    return '0.4';
  }

  return '1';
};

var getOpacityImg = function getOpacityImg(_ref5) {
  var disabled = _ref5.disabled;

  if (disabled) {
    return '0.2';
  }

  return '1';
};

var getCursor = function getCursor(_ref6) {
  var disabled = _ref6.disabled;

  if (disabled) {
    return 'default';
  }

  return 'pointer';
};

export var VariantTileContent = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  color: ", ";\n  background-color: ", ";\n  padding: 8px 12.8px;\n  border: 1.3px solid #323436;\n  opacity: ", ";\n  cursor: ", ";\n  font-size: 14px;\n  border-radius: 6px;\n"])), function (props) {
  return getFontColor(props);
}, function (props) {
  return getBgColor(props);
}, function (props) {
  return getOpacity(props);
}, function (props) {
  return getCursor(props);
});

var getTSize = function getTSize(props) {
  if (props.tSize === "small") {
    return "31px";
  }

  return "50px";
};

export var VariantTileContentImage = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  color: ", ";\n  height: ", ";\n  opacity: ", ";\n  cursor: ", ";\n"])), function (props) {
  return getFontColor(props);
}, function (props) {
  return getTSize(props);
}, function (props) {
  return getOpacityImg(props);
}, function (props) {
  return getCursor(props);
});
export var VariantTileImage = styled.img(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  \n"])));