import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { media, styled } from "@styles";
export var Label = styled.label(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  min-height: 190px;\n  display: block;\n  background-color: ", ";\n  padding: 30px;\n  padding: ", ";\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n\n  ", "\n"])), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked ? "28px" : "30px";
}, function (props) {
  return props.checked && "border: 2px solid #21125E;";
}, function (props) {
  return props.theme.typography.smallFontSize;
}, media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]))));
export var Input = styled.input(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: none;\n"])));