import { PAYPAL_EXPRESS_WEBHOOK_URL } from '../config';

function createPaymentAuthnet() {
    return new Promise(function(resolve, reject) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var data = JSON.parse(this.responseText);
            resolve(data);
        };
        xhr.onerror = reject;
        xhr.open('POST', PAYPAL_EXPRESS_WEBHOOK_URL, true);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

        var params = 'action=CREATEPAYMENT'
                        + '&token=' 
                        + "test";
        xhr.send(params);
    });
}

export function paypalExpressClicked(){
    
    var promise = createPaymentAuthnet();
    promise.then(function (data) {	
        console.log(data);
    }); 

}