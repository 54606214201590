import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

import gql from "graphql-tag";
import { TypedQuery } from "../../core/queries";
export var priceFragment = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment Price on TaxedMoney {\n    gross {\n      amount\n      currency\n    }\n    net {\n      amount\n      currency\n    }\n  }\n"])));
export var basicProductFragment = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  fragment BasicProductFields on Product {\n    id\n    name\n    thumbnail {\n      url\n      alt\n    }\n    thumbnail2x: thumbnail(size: 510) {\n      url\n    }\n    metadata {\n      key\n      value\n    }\n    productType {\n      id\n      metadata {\n        key\n        value\n      }\n    }\n    images {\n      id\n      url\n      alt\n    }\n  }\n"])));
export var productPricingFragment = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductPricingField on Product {\n    pricing {\n      onSale\n      priceRangeUndiscounted {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n      priceRange {\n        start {\n          ...Price\n        }\n        stop {\n          ...Price\n        }\n      }\n    }\n  }\n"])), priceFragment);
export var selectedAttributeFragment = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  fragment SelectedAttributeFields on SelectedAttribute {\n    attribute {\n      id\n      name\n    }\n    values {\n      id\n      name\n    }\n  }\n"])));
export var productVariantFragment = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  fragment ProductVariantFields on ProductVariant {\n    id\n    sku\n    name\n    isAvailable\n    quantityAvailable(countryCode: $countryCode)\n    images {\n      id\n      url\n      alt\n    }\n    pricing {\n      onSale\n      priceUndiscounted {\n        ...Price\n      }\n      price {\n        ...Price\n      }\n    }\n    attributes {\n      attribute {\n        id\n        name\n        slug\n        metadata {\n          key\n          value\n        }\n      }\n      values {\n        id\n        name\n        value: name\n      }\n    }\n  }\n"])), priceFragment);
export var productDetailsQuery = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  ", "\n  ", "\n  query ProductDetails($id: ID!, $countryCode: CountryCode) {\n    product(id: $id) {\n      ...BasicProductFields\n      ...ProductPricingField\n      descriptionJson\n      category {\n        id\n        name\n        products(first: 3) {\n          edges {\n            node {\n              ...BasicProductFields\n              ...ProductPricingField\n            }\n          }\n        }\n      }\n      images {\n        id\n        alt\n        url\n      }\n      attributes {\n        ...SelectedAttributeFields\n      }\n      variants {\n        ...ProductVariantFields\n      }\n      seoDescription\n      seoTitle\n      isAvailable\n      isAvailableForPurchase\n      availableForPurchase\n      productType {\n        metadata {\n          key\n          value\n        }\n      }\n    }\n  }\n"])), basicProductFragment, selectedAttributeFragment, productVariantFragment, productPricingFragment); // FIXME: Check how to handle pagination of `productVariants` in the UI.
// We need allow the user view  all cart items regardless of pagination.

export var productVariantsQuery = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query VariantList($ids: [ID!], $countryCode: CountryCode) {\n    productVariants(ids: $ids, first: 100) {\n      edges {\n        node {\n          ...ProductVariantFields\n          product {\n            ...BasicProductFields\n          }\n        }\n      }\n    }\n  }\n"])), basicProductFragment, productVariantFragment);
export var TypedProductDetailsQuery = TypedQuery(productDetailsQuery);
export var TypedProductVariantsQuery = TypedQuery(productVariantsQuery);