import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Grid = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"])), media.smallScreen(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]))));
export var Divider = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"])), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n  font-size: 16.5px;\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
});
export var SubTitle = styled.h4(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: 0.6rem 0 1.4rem 0;\n  font-size: ", ";\n  color: rgba(50, 50, 50, 0.6);\n"])), function (props) {
  return props.theme.typography.baseFontSize;
});
export var TextSummary = styled.p(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.h4FontSize;
});
export var ErrorMessages = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"])));