import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: auto;\n  display: flex;\n  flex-direction: column;\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.smallFontSize;
});
export var SortLine = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding-bottom: 0.5rem;\n"])));
export var SortWrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  margin-bottom: -1px;\n  margin-left: 10px;\n"])));
export var Value = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  cursor: pointer;\n"])));
export var Indicator = styled.div(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  right: 1rem;\n  cursor: pointer;\n  margin-left: 16px;\n"])));