import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;

import { media, styled } from "@styles";
export var AddressForm = styled.form(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  ", "\n"])), media.smallScreen(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      width: 98%;\n  "]))));
export var Wrapper = styled.div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  flex-wrap: wrap;\n"])));
export var RowWithTwoCells = styled.div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  & > div {\n    width: calc(50% - ", " / 2);\n    ", "\n  }\n"])), function (props) {
  return props.theme.spacing.fieldSpacer;
}, media.smallScreen(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      width: 100%;\n    "]))));
export var RowWithThreeCells = styled.div(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n  & > div {\n    width: 32%;\n    ", "\n  }\n"])), media.smallScreen(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n      width: 100%;\n    "]))));
export var RowWithOneCell = styled.div(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  width: 100%;\n"])));