import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  overflow-y: scroll;\n  overflow-x: hidden;\n  width: 350px;\n  display: flex;\n  justify-content: flex-start;\n  flex-direction: column;\n  align-items: center;\n\n  box-shadow: 6px 0px 30px rgba(0, 0, 0, 0.15);\n"])));
export var Header = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  display: flex;\n  width: 80%;\n  justify-content: space-between;\n  align-items: center;\n  margin-top: 1.5rem;\n  margin-bottom: 4rem;\n  padding: 0;\n\n  font-weight: ", ";\n  font-size: ", ";\n"])), function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.typography.h3FontSize;
});