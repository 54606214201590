import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral([""])));
export var Description = styled.div(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  font-size: ", ";\n  color: ", ";\n\n  padding-bottom: 0.25rem;\n"])), function (props) {
  return props.theme.typography.smallFontSize;
}, function (props) {
  return props.theme.colors.dark;
});